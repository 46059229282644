<template>
  <b-card>
    <b-form>
      <b-row>
        <b-col cols="12">
          <b-form-group>
            <b-media no-body>
              <b-media-aside>
                <b-link>
                  <b-avatar
                    v-if="form.image"
                    ref="previewEl"
                    :src="$FILES_URL + form.image"
                    size="90px"
                    rounded
                    icon="ImageIcon"
                  />
                  <b-avatar
                    v-else
                    size="90px"
                    rounded
                    text="IMG"
                  />
                </b-link>
              </b-media-aside>

              <b-media-body class="mt-75 ml-75">
                <!-- upload button -->
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  size="sm"
                  class="mb-75 mr-75"
                  @click="$refs.refOrganizationImageInputEl.$el.click()"
                >
                  Upload
                </b-button>
                <b-form-file
                  ref="refOrganizationImageInputEl"
                  accept=".jpg, .png, .gif"
                  :hidden="true"
                  plain
                  @change="uploadOrganizationImage"
                />
                <b-card-text
                  >Allowed JPG, GIF or PNG. Max size of 800kB</b-card-text
                >
              </b-media-body>
            </b-media>
          </b-form-group>
        </b-col>
        <b-col cols="4">
          <b-form-group label="Name">
            <b-form-input v-model="form.name" />
          </b-form-group>
        </b-col>
        <b-col cols="4">
          <b-form-group label="Email">
            <b-form-input v-model="form.email" />
          </b-form-group>
        </b-col>
        <b-col cols="4">
          <b-form-group label="Mobile Number">
            <b-form-input type="number" v-model="form.mobile_number" />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group label="Address">
            <quill-editor v-model="form.address" :options="{ theme: 'snow' }" />
          </b-form-group>
        </b-col>
        <b-col cols="4">
          <b-form-group label="Contact Person">
            <b-form-input v-model="form.contact_person" />
          </b-form-group>
        </b-col>
        <b-col cols="4">
          <b-form-group label="Registration Number">
            <b-form-input type="number" v-model="form.registration_number" />
          </b-form-group>
        </b-col>
        <b-col cols="4">
          <b-form-group label="Tax Type">
            <b-form-input v-model="form.tax_type" />
          </b-form-group>
        </b-col>
        <!-- <b-col cols="4">
          <b-form-group label="Base Currency">
            <b-form-input v-model="form.base_currency" />
          </b-form-group>
        </b-col> -->
        <b-col cols="4">
          <b-form-group label="Tax Number">
            <b-form-input type="number" v-model="form.tax_number" />
          </b-form-group>
        </b-col>
        <b-col cols="4">
          <b-form-group label="Base Currency">
            <b-form-input v-model="form.base_currency" />
          </b-form-group>
        </b-col>
        <b-col cols="4">
          <b-form-group label="License Period">
            <b-form-input type="number" v-model="form.license_period" />
          </b-form-group>
        </b-col>
        <b-col cols="4">
          <b-form-group label="User Limit">
            <b-form-input type="number" v-model="form.user_limit" />
          </b-form-group>
        </b-col>
        <b-col cols="4">
          <b-form-group label="Contract Limit">
            <b-form-input type="number" v-model="form.contract_limit" />
          </b-form-group>
        </b-col>
        <b-col cols="4">
          <b-form-group label="Notification before days">
            <b-form-input
              type="number"
              v-model="form.notification_before_days"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-button variant="primary" @click="saveOrganizationDetails"
            >Save</b-button
          >
        </b-col>
      </b-row>
    </b-form>
  </b-card>
</template>

<script>
import {
  BCol,
  BRow,
  BCard,
  BForm,
  BFormInput,
  BFormGroup,
  BButton,
  BFormFile,
  BMedia,
  BMediaAside,
  BCardText,
  BMediaBody,
  BLink,
  BAvatar,
  BIcon,
} from "bootstrap-vue";
import * as OriganizationServices from "@/apiServices/OrganizationServices";
import ToastificationContentVue from "@/@core/components/toastification/ToastificationContent.vue";
import Ripple from "vue-ripple-directive";

import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import { quillEditor } from "vue-quill-editor";

import { uploadImages } from "@/utils/helpers";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BForm,
    BFormInput,
    BFormGroup,
    quillEditor,
    BButton,
    BFormFile,
    BMediaAside,
    BMedia,
    BCardText,
    BMediaBody,
    BLink,
    BAvatar,
    BIcon,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      form: {
        image: "",
        name: "",
        email: "",
        mobile_number: "",
        address: "",
        logo: "",
        contact_person: "",
        registration_number: "",
        tax_type: "",
        base_currency: "",
        tax_number: "",
        license_period: "",
        user_limit: "",
        contract_limit: "",
        notification_before_days: "",
      },
    };
  },
  methods: {
    async uploadOrganizationImage(e) {
      const file = e.target.files[0];

      if (file.size > 800 * 1024) {
        this.$toast({
          component: ToastificationContentVue,
          props: {
            title: "File size cannot be greater than 800kbs",
            icon: "EditIcon",
            variant: "warning",
          },
        });
      }

      const fileUrl = await uploadImages(e);
      if (fileUrl && fileUrl.length) {
        this.form.image = fileUrl[0];
      }
    },

    async saveOrganizationDetails() {
      try {
        const response = await OriganizationServices.saveOrganizationDetails(
          this.form
        );
        if (response.data.status) {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: response.data.message || "Success",
              icon: "EditIcon",
              variant: "success",
            },
          });
        } else {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: response.data.message || "Not Saved",
              icon: "EditIcon",
              variant: "success",
            },
          });
        }
      } catch (err) {
        console.log("Error in saving organization ", err);
      }
    },
    async getOriganizationDetails() {
      try {
        const response = await OriganizationServices.getOrganizationDetails();
        if (response.data.status) {
          this.form = {
            ...this.form,
            ...response.data.data,
          };
        }
      } catch (err) {
        console.log("Error in getting organization details ", err);
      }
    },
  },
  beforeMount() {
    this.getOriganizationDetails();
  },
};
</script>

<style>
</style>